import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import MessageEditor from './MessageEditor';

const AEditJobPage = () => {
  const { jobId } = useParams();

  const [formData, setFormData] = useState({
    end_date: '',
    title: '',
    description: '',
    requirements: '',
  });

  const [loading, setLoading] = useState(false);

  const getFormattedDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/${jobId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }
        const data = await response.json();
        setFormData({
          end_date: data.end_date || getFormattedDate(),
          title: data.title,
          description: data.description,
          requirements: data.requirements,
        });
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditorChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleUpdateJob = async () => {
    const { end_date, title, description, requirements } = formData;

    if (!end_date || !title || !description || !requirements) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/${jobId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ end_date, title, description, requirements }),
      });

      const result = await response.json();

      if (response.ok) {
        alert('Job updated successfully!');
        setFormData({
          end_date: getFormattedDate(),
          title: '',
          description: '',
          requirements: '',
        });
      } else {
        alert('Failed to update job: ' + result.message);
      }
    } catch (error) {
      console.error('Error updating job:', error);
      alert('An error occurred while updating the job.');
    }
  };

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>Edit Job</h3>
      </div>

      <div className='AaPost_Sec'>
        {loading ? (
          <p>Loading job details...</p>
        ) : (
          <>
            <div className='Post_input'>
              <input
                type='date'
                name='end_date'
                value={formData.end_date}
                onChange={handleInputChange}
              />
            </div>

            <div className='Post_input'>
              <input
                type='text'
                placeholder='Job Title'
                name='title'
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>

            <div className='Post_input'>
              <p>Job Description</p>
              <MessageEditor
                value={formData.description}
                onChange={(value) => handleEditorChange('description', value)}
              />
            </div>

            <div className='Post_input'>
              <p>Job Requirements</p>
              <MessageEditor
                value={formData.requirements}
                onChange={(value) => handleEditorChange('requirements', value)}
              />
            </div>

            <div className='Post_input'>
              <button onClick={handleUpdateJob}>Update Job</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AEditJobPage;
