import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import SiteLogo from '../components/Img/site-logo.png';
import DashboardIcon from './Img/dashboard-icon.svg';
import MessageIcon from './Img/message-icon.svg';
import JobIcon from './Img/job-icon.svg';
import NewsIcon from './Img/news-icon.svg';
import LogoutIcon from './Img/logout-icon.svg';
import SettingsIcon from './Img/settings-icon.svg';
import ImagePlaceholder from './Img/image-placeholder.png';
import AddBox from './Img/add-box.svg';
import AlertIcon from './Img/alert-icon.svg';
import MassgeAlertIcon from './Img/message-icon-Alert.svg';
import VisitorsIcon from './Img/visitors-icon.svg';

const AdminNavBar = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const location = useLocation(); // Get current path
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  const closeNav = () => {
    setIsNavActive(false);
  };

  const handleLogout = () => {
    // Confirmation dialog
    const confirmLogout = window.confirm('Are you sure you want to logout from your admin?');
    if (confirmLogout) {
      // Clear user authentication details (example: from local storage or context)
      localStorage.removeItem('user'); // or context if using AuthContext
      navigate('/admin/admin-login'); // Redirect to the login page
    }
  };

  return (
    <div className={`AdminNav ${isNavActive ? 'active_AaNav' : ''}`}>
      <nav className='AdminLeftNav1'>
        <div className='Aa_TopTt'>
          <span>S</span>
        </div>

        <div className='Gga_Ull_ul'>
          <ul>
            <li>
              <Link to="/admin" className={location.pathname === '/admin' ? 'Active_AAD_Icn' : ''}>
                <img src={DashboardIcon} alt='Dashboard icon' />
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/messages" className={location.pathname === '/admin/messages' ? 'Active_AAD_Icn' : ''}>
                <img src={MessageIcon} alt='Message icon' />
                <span>Message</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/jobs" className={location.pathname === '/admin/jobs' ? 'Active_AAD_Icn' : ''}>
                <img src={JobIcon} alt='Job icon' />
                <span>Posted Jobs</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/news" className={location.pathname === '/admin/news' ? 'Active_AAD_Icn' : ''}>
                <img src={NewsIcon} alt='News / Blogs icon' />
                <span>News / Blogs</span>
              </Link>
            </li>
          </ul>

          <ul>
            {/* Uncomment if needed */}
            {/* <li>
              <Link to="/admin/settings" className={location.pathname === '/admin/settings' ? 'Active_AAD_Icn' : ''}>
                <img src={SettingsIcon} alt='Settings icon' />
                <span>Settings</span>
              </Link>
            </li> */}

            <li>
              <button onClick={handleLogout} className='logout-button'>
                <img src={LogoutIcon} alt='Logout icon' />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </nav>

      <nav className='AdminLeftNav2'>
        <div>
          <div className='Mobile_BabnS'>
            <button className='close_AdimAa_Btn' onClick={closeNav}></button>
          </div>
          <div className='HHaggs_TOppso'>
            <Link to="/admin" className='aA_Logo'>
              <img src={SiteLogo} alt='site logo' />
            </Link>
            <div className='Baggbs_sec'>
              <img src={ImagePlaceholder} alt='Image placeholder' />
              <div className='cbalks'>
                <div>
                  <p>Admin</p>
                  <span>Simul Management Solutions</span>
                </div>
              </div>
            </div>
          </div>

          <div className='akkjsks_sec'>
            <div className='banbsg'>
              <div className='banbsg_1'>
                <p>Upload News / Blog</p>
                <Link to="/admin/post-news" className='banbsg_1_Box banbsg_1_Box_1'>
                  <img src={AddBox} alt='Add box' />
                  <span>Click to add</span>
                </Link>
              </div>
              <div className='banbsg_1'>
                <p>Post a Job</p>
                <Link to="/admin/post-job" className='banbsg_1_Box banbsg_1_Box_2'>
                  <img src={AddBox} alt='Add box' />
                  <span>Click to add</span>
                </Link>
              </div>
            </div>

            <div className='messageAlert'>
              <h4>
                <img src={AlertIcon} alt='Alert icon' />
                Message Alert
              </h4>
              <Link to="/admin/messages" className='messageAlert_Box'>
                <div className='messageAlert_Main'>
                  <div>
                    <p>You have a new message from a user</p>
                    <span>Fri 16 , May 2024</span>
                  </div>
                </div>
                <div className='messageAlert_Icon'>
                  <img src={MassgeAlertIcon} alt='Message alert icon' />
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className='visitors_Btn_Sec'>
          <Link to="/admin/visitors" className='visitors_Btn'>
            <img src={VisitorsIcon} alt='visitors icon' />
            Site visitors
          </Link>
        </div>
      </nav>

      <div className='HHaggs_TOppso HHaggs_TOppso_Pc'>
        <div className='kkajs-ha'>
          <button className='MoNBl_toggle_AaNAv' onClick={toggleNav}></button>
          <Link to="/admin" className='aA_Logo'>
            <img src={SiteLogo} alt='site logo' />
          </Link>
        </div>
        <div className='Baggbs_sec'>
          <img src={ImagePlaceholder} alt='Image placeholder' />
          <div className='cbalks'>
            <div>
              <p>Admin</p>
              <span>Simul Management Solutions</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavBar;
