

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config'; // Assuming you have a config file for API_BASE_URL

const ANewsPage = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);   
  const [previousPage, setPreviousPage] = useState(null);  
  const [currentPageUrl, setCurrentPageUrl] = useState(`${config.API_BASE_URL}/api/news/update/news/`); 

  // Fetch news data based on the current page URL
  const fetchNews = async (url) => {
    try {
      setLoading(true);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch news');
      }
      const data = await response.json();
      setNewsData(data.results); // Store the news articles
      setNextPage(data.next); // Store the next page URL
      setPreviousPage(data.previous); // Store the previous page URL
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(currentPageUrl); // Fetch the news when component mounts or URL changes
  }, [currentPageUrl]);

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPageUrl(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      setCurrentPageUrl(previousPage);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this news item?");
    if (confirmDelete) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/news/update/news/${id}/`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error('Failed to delete the news item');
        }
        // Remove the deleted news item from the state
        setNewsData(newsData.filter((news) => news.id !== id));
      } catch (err) {
        alert(err.message); // Handle any error during the deletion process
      }
    }
  };

  if (loading) {
    return <div>Loading news...</div>;
  }

  if (error) {
    return <div>Error fetching news: {error}</div>;
  }

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>News / Blogs</h3>
        <span>{newsData.length}</span>
      </div>

      <div className='Latest_News_Grid'>
        {newsData.map((news) => (
          <div key={news.id} className="news-card">
            <div className='News_Card_Main'>
              <div className='All_Aaa_Btns Allja_width'>
                <Link 
                  to={`/admin/read-news/${news.id}/${encodeURIComponent(news.title)}`}  
                  className='Gg_btn main_Gg_btn'
                >
                  Read News
                </Link>
                <Link 
                  to={`/admin/edit-news/${news.id}/${encodeURIComponent(news.title)}`} 
                  className='Gg_btn'
                >
                  Edit
                </Link>

                <button
                  className='Gg_btn'
                  onClick={() => handleDelete(news.id)}
                >
                  Remove
                </button>
              </div>

              {news.image ? (
                <img src={news.image} alt={news.title} className="news-banner" />
              ) : (
                <img src={news.image} alt={news.title} className="news-banner" />
              )}
              <div className='News_Card_Dlt'>
                <h3>{news.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: news.description }} />
                
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination buttons for navigating between pages */}
      <div className="pagination-buttons">
        <button
          onClick={handlePreviousPage}
          disabled={!previousPage}
          className='btn btn-danger btn-lg mx-2'
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={!nextPage}
          className='btn btn-primary btn-lg mx-2'
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ANewsPage;
