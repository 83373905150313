// src/admin/AdminPage.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext'; // Import the useAuth hook
import AdminNavBar from './AdminNavBar';
import AHome from './AHomePage';
import AMessage from './AMessagePage';
import ANews from './ANewsPage';
import AJobs from './AJobsPage';
import AReadNews from './AReadNewsPage';
import AEditNewsPage from './AEditNewsPage';
import APostJob from './APostJobPage';
import AEditJobPage from './AEditJobPage'; 
import APostNews from './APostNewsPage';
import AVisitors from './AVisitorsPage';

import './main.css';

const AdminPage = () => {
  const { isAuthenticated } = useAuth(); // Access authentication state

  if (!isAuthenticated) {
    return <Navigate to="/admin/admin-login" replace />; // Redirect to login if not authenticated
  }

  return (
    <div className='AdminPage'>
      <AdminNavBar /> {/* This will remain static across all routes */}

      <div className='Main_Admin_Page'>
        {/* Routes for different pages under "/admin" */}
        <Routes>
          <Route path="/" element={<AHome />} />
          <Route path="/messages" element={<AMessage />} />
          <Route path="/jobs" element={<AJobs />} />
          <Route path="/news" element={<ANews />} />
          <Route path="/post-job" element={<APostJob />} />
          <Route path="/read-news/:id/:title" element={<AReadNews />} />
          <Route path="/edit-news/:id/:title" element={<AEditNewsPage />} />
          <Route path="/edit-job/:jobId/:title" element={<AEditJobPage />} />
          <Route path="/post-news" element={<APostNews />} />
          <Route path="/visitors" element={<AVisitors />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminPage;
