import React, { useState } from 'react';

const FAQDetail = ({ section }) => {
  const [activeIndex, setActiveIndex] = useState(null); // Track the active question

  // Define FAQ data for each section
  const faqData = {
    "Call Answering": [
        {
          question: 'How does Simul’s call answer service work?',
          answer: 'Our 24/7 call answering service ensures that your business never misses a call. We handle customer inquiries, schedule appointments, process orders, and more—on your behalf. We personalize the service to meet your business needs, providing professional call handling in multiple languages and across different time zones.'
        },
        {
          question: 'How do I get started with Simul\'s call answering services?',
          answer: 'Getting started is easy. Simply reach out through our website’s contact form or give us a call, and we’ll walk you through the setup process, including customizing your call scripts, setting up call forwarding, and training our team to handle your specific needs.'
        },
        {
          question: 'Do you provide any special services for event organizers?',
          answer: 'Yes, Simul offers tailored call answering services for event organizers, ensuring every inquiry is answered and managed effectively. We handle outbound and inbound calls, helping you stay organized and ensuring your event runs smoothly.'
        },
        {
          question: 'How do Simul’s call answering services handle after-hours calls?',
          answer: 'Our 24/7 call answering services ensure that after-hours calls are answered by our professional team. Whether it’s late at night or early morning, we offer prompt customer service, message-taking, and appointment scheduling so that your business is always available.'
        }
      ],
   "Recruitment": [
      {
        question: 'What is the recruitment process with Simul?',
        answer: `
          Our recruitment process follows a structured approach to ensure that we find the best candidates for your business:
          • Initial Consultation: We discuss your business needs and industry-specific requirements.
          • Talent Search: We source candidates based on their skills, qualifications, and cultural fit.
          • Screening & Interviewing: Our team conducts thorough interviews and background checks.
          • Hiring & Onboarding: Once you select a candidate, we assist with onboarding and any necessary compliance.
          • Post-Hire Support: We follow up to ensure satisfaction and provide ongoing recruitment support as needed.
        `
      },
      {
        question: 'How long does the recruitment process take?',
        answer: 'The timeline depends on the role and your specific requirements. Typically, the process can take between two to six weeks depending on the industry and location. We strive to expedite the process while ensuring we find the best match for your needs.'
      },
      {
        question: 'What industries does Simul specialize in for recruitment?',
        answer: `
          Simul specializes in recruiting talent across multiple sectors, including:
          • Healthcare: Nurses, care professionals, administrative staff, and compliance managers.
          • IT: From infrastructure and development to project management and digital transformation.
          • Engineering: We recruit experienced engineers in mechanical, civil, software, and more.
          • Remote Data Entry: Skilled data entry personnel to support administrative functions.
          We have dedicated teams experienced in each industry, ensuring we find the best fit for your staffing needs.
        `
      },
      {
        question: 'Can I track the progress of my recruitment process?',
        answer: 'Yes, we provide regular updates throughout the recruitment process. You’ll be informed about every step; from candidate sourcing to final selection. This transparency allows you to stay on top of the hiring process without any guesswork.'
      },
      {
        question: 'How does Simul’s global recruitment work?',
        answer: 'We provide recruitment solutions to businesses worldwide. We handle international hiring, including compliance with local employment laws, ensuring seamless onboarding and payroll management for remote or in-country employees. Whether you\'re in North America, Europe, Asia, or Africa, we’ve got you covered.'
      },
      {
        question: 'How does Simul’s pricing work for recruitment services?',
        answer: 'Our pricing structure is straightforward and transparent. We offer flexible options depending on the scale and urgency of your recruitment needs. Contact us for a custom quote based on your specific requirements.'
      }
    ],

    "Consulting": [
        {
          question: 'What does Simul’s consulting service cover for Nigerians in Diaspora?',
          answer: `
            Our consultancy service is tailored to Nigerians living abroad who have business interests or plan to start a business in Nigeria. 
            We offer full-service support, including managing money transfers, tax advice, legal assistance, property/real estate purchases, 
            and providing representation for any business or legal needs. Our goal is to make business easier for you, even from abroad.
          `
        },
        {
          question: 'Can you assist with tax issues for Nigerians in the Diaspora?',
          answer: `
            Yes, our consultancy team specializes in handling tax matters for Nigerians abroad, ensuring you comply with Nigerian tax laws 
            while optimizing your tax strategies. Simul’s consultancy services are focused on helping businesses navigate their challenges 
            and capitalize on opportunities.
          `
        },
        {
          question: 'What if I need someone to represent me in Nigeria for business or legal matters?',
          answer: `
            Simul provides representation services for Nigerians abroad, whether you need someone to attend business meetings, handle legal issues, 
            or manage property transactions on your behalf. Our trusted team will act in your best interest, keeping you updated every step of the way.
          `
        },
        {
          question: 'What makes Simul different from other consulting firms?',
          answer: `
            We offer a unique combination of experience, global reach, and personalized service. We understand the importance of compliance, security, 
            and transparency, especially when working with international clients. Our industry-specific expertise ensures that you always get the best service, 
            and our 24/7 consultancy services are designed to support your business no matter where you're located.
          `
        }
      ],

      "Privacy and Security": [
        {
          question: 'How does Simul ensure my data is secure?',
          answer: `
            We prioritize the security of your data with advanced encryption, firewalls, and secure data centres. 
            Additionally, we regularly conduct security audits, train our staff in the latest data protection measures, 
            and comply with international data protection laws such as GDPR. Your information is only accessed by authorized 
            personnel and protected against unauthorized access.
          `
        },
        {
          question: 'Is Simul Management Solutions compliant with GDPR?',
          answer: `
            Yes, Simul is fully compliant with the General Data Protection Regulation (GDPR). We follow strict data protection 
            guidelines, ensuring that personal and business data are processed lawfully, transparently, and securely. You have 
            full control over your data, including rights to access, rectify, delete, and restrict the processing of your information.
          `
        },
        {
          question: 'What are my rights as a customer in terms of data protection?',
          answer: `
            As a customer, you have full rights over your data, including:
            - Access to the data we hold about you.
            - Correction of any inaccuracies.
            - Deletion of your personal data, under certain conditions.
            - Restriction of data processing or withdrawal of consent at any time.
          `
        }
      ],
      
  };

  // Check if there are FAQs for the selected section
  const faqs = faqData[section];

  if (!faqs) {
    return <p>No FAQs available for this section.</p>; // Display this if the section is not found
  }

  // Toggle the accordion on click
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the active question if it's clicked again
    } else {
      setActiveIndex(index); // Open the clicked question
    }
  };

  return (
    <div className="faq-section">
      <div className="site-container">
        <h2 className="big-text">{section} FAQ</h2>
        <div className="faq_Main">
          <ul>
            {faqs.map((item, index) => (
              <li key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                <div className="faq-content">
                  <div className="faq-question" onClick={() => toggleAccordion(index)}>
                    <h3>{item.question}</h3>
                    <span>{activeIndex === index ? '-' : '+'}</span> {/* Toggle icon */}
                  </div>
                  <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                    <p>{item.answer}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQDetail;
