import React, { useState, useEffect, useRef } from 'react';
import MessageIcon from './Img/message-icon.svg';
import config from '../config';

const AMessagePage = () => {
  const [messages, setMessages] = useState([]);
  const [replyVisible, setReplyVisible] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(`${config.API_BASE_URL}/api/messages/update/messages/`);
  const [replyLoading, setReplyLoading] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({}); // Loading state for each delete action
  const textareaRefs = useRef({});
  const [flashMessage, setFlashMessage] = useState('');

  const fetchMessages = async (pageUrl) => {
    try {
      const response = await fetch(pageUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }
      const data = await response.json();
      setMessages(data.results);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages(currentPage);
  }, [currentPage]);

  const toggleReply = (messageIndex) => {
    setReplyVisible((prevState) => ({
      ...prevState,
      [messageIndex]: !prevState[messageIndex],
    }));
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      setCurrentPage(previousPage);
    }
  };

  const sendReply = async (messageId, index, user, userEmail) => {
    const replyText = textareaRefs.current[index].value;
  
    if (messages[index].replies.length > 0) {
      // Show confirmation dialog if there is already a reply
      const confirmReply = window.confirm(
        "This message has already been replied to. Are you sure you want to send another reply?"
      );
      if (!confirmReply) return; // Exit if the admin chooses not to send another reply
    }
  
    const payload = {
      user: user,
      userEmail: userEmail,
      message: messageId,
      reply_text: replyText,
      admin_user: "Admin2",
    };
  
    try {
      setReplyLoading((prev) => ({ ...prev, [index]: true }));
      const response = await fetch(`${config.API_BASE_URL}/api/messages/update/replies/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send reply');
      }
  
      textareaRefs.current[index].value = '';
      setFlashMessage('Reply sent successfully!');
      setTimeout(() => setFlashMessage(''), 3000);
  
    } catch (error) {
      console.error(error);
    } finally {
      setReplyLoading((prev) => ({ ...prev, [index]: false }));
    }
  };
  

  const deleteMessage = async (messageId, index) => {
    const confirmed = window.confirm("Are you sure you want to delete this message?");
    if (!confirmed) return; // Exit if not confirmed
  
    try {
      setDeleteLoading((prev) => ({ ...prev, [index]: true }));
      const response = await fetch(`${config.API_BASE_URL}/api/messages/update/messages/${messageId}/`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete message');
      }
  
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
      setFlashMessage('Message removed successfully!');
      setTimeout(() => setFlashMessage(''), 3000);
  
    } catch (error) {
      console.error(error);
      setFlashMessage('Error removing the message.');
      setTimeout(() => setFlashMessage(''), 3000);
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [index]: false }));
    }
  };
  

  if (loading) {
    return <div>Loading messages...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>Messages</h3>
        <span>{messages.length}</span>
      </div>

      {flashMessage && (
        <div className="flash-message">
          {flashMessage}
        </div>
      )}

      <div className='Mmss_sec'>
        {messages.map((msg, index) => (
          <div className='message_Box' key={msg.id}>
            <div className='message_Box_Tt'>
              <div className='message_Box_Tt_1'>
                <span>{new Date(msg.created_at).toLocaleString()}</span>
              </div>
              <div className='message_Box_Tt_2'>
              <div className='All_Aaa_Btns'>
                <button
                  className='Gg_btn main_Gg_btn'
                  onClick={() => toggleReply(index)}
                  disabled={msg.replies.length > 0} // Disable button if there are replies
                >
                  <img src={MessageIcon} alt='Message icon' />
                  {msg.replies.length > 0 ? 'Already Replied' : replyVisible[index] ? 'Cancel reply' : 'Reply message'}
                </button>
                <button 
                  className='Gg_btn' 
                  onClick={() => deleteMessage(msg.id, index)}
                  disabled={deleteLoading[index]}
                >
                  {deleteLoading[index] ? 'Removing...' : 'Remove'}
                </button>
              </div>

              </div>
            </div>

            {replyVisible[index] && (
              <div className='reply_box'>
                <textarea 
                  ref={el => textareaRefs.current[index] = el} 
                  placeholder='Drop a message'
                ></textarea>
                <button 
                  onClick={() => sendReply(msg.id, index, msg.user, msg.userEmail)}
                  disabled={replyLoading[index]}
                >
                  {replyLoading[index] ? 'Sending...' : 'Send reply'}
                </button>
              </div>
            )}

            <div className='message_Body'>
              <div className='message_Body_ttaay'>
                <h3>{msg.full_name + "  "  || 'Anonymous'}</h3>
                <a href='#'>{msg.userEmail || 'No email provided'}</a>
              </div>
              <ul>
                <li>
                  <span>Phone Number</span>
                  <p>{msg.phone_number}</p>
                </li>

                <li>
                  <span>Service of Interest</span>
                  <p>{msg.service_of_interest}</p>
                </li>
              </ul>
              <p className='messaget_ooaoa'>{msg.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='pagination_controls d-flex justify-content-center mt-4'>
        <button onClick={handlePreviousPage} disabled={!previousPage} className='btn btn-danger btn-lg mx-2'>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={!nextPage} className='btn btn-primary btn-lg mx-2'>
          Next
        </button>
      </div>
    </div>
  );
};

export default AMessagePage;
