import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Arrow1 from '../components/Img/arrow1.svg';

import SearchIcon from './Img/search-icon.svg';
import angleDownIcon from './Img/angle-down.svg';
import SiteLogo from './Img/site-logo.png';

import ServicesImg1 from '../components/Img/Services1/3.jpg';
import ServicesImg2 from '../components/Img/Services2/4.jpg';
import ServicesImg3 from '../components/Img/Services3/3.jpg';
import ServicesImg4 from '../components/Img/Services4/2.jpg';

const TopNavbar = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [hoveredService, setHoveredService] = useState(null);
  const [isHoveringDetails, setIsHoveringDetails] = useState(false); // New state

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleCloseSearch = () => {
    setIsSearchVisible(false);
  };

  const services = [
    {
      id: 1,
      title: 'Call Handling & Management Solutions',
      description: 'Businesses often encounter challenges in managing calls effectively, including concerns regarding response times, customer satisfaction, and operational efficiency.',
      img: ServicesImg1,
      link: '/caling-handling-management-solutions',
    },
    {
      id: 2,
      title: 'Recruitment Services',
      description: 'At Simul Management Solutions, we recognize that your business success depends on having the right talent in place. Our dedicated recruitment team is committed to helping you find and hire top professionals tailored to your specific needs. With our comprehensive approach to recruitment, we ensure that you not only fill positions but also build a team that drives your business forward.',
      img: ServicesImg2,
      link: '/recruitment-services',
    },
    {
      id: 3,
      title: 'Consultation Services',
      description: 'At Simul Management Solutions, we offer tailored consultation services designed to meet the unique needs of your business. Our experts are here to guide you through every step of the process!',
      img: ServicesImg3,
      link: '/consultation-services',
    },
    {
      id: 4,
      title: 'Business Support Services',
      description: 'Looking for expert support to elevate your business operations? We specialize in tailored solutions that help your organization thrive. Ready to enhance your operational efficiency?',
      img: ServicesImg4,
      link: '/business-support-services',
    },
  ];

  const handleMouseLeave = () => {
    if (!isHoveringDetails) {
      setHoveredService(null);
    }
  };

  return (
    <nav className={`Top_NavBar  ${isSearchVisible ? 'Show_Search_DropDown' : ''}`}>
      <div className='site-container'>
        <div className='Nav_Content'>
          <div className='Left_L_Main_Nav'>
            <Link to="/" className='site-logo'>
              <img src={SiteLogo} alt='Site Logo' />
            </Link>
          </div>
          <ul className='Rr_Tt_Ul'>
            <li>
              <Link to="/" aria-label="Go to Home">Home</Link>
            </li>
            <li>
              <Link to="/" aria-label="Home" onClick={(e) => e.preventDefault()}>
                Services <img src={angleDownIcon} alt='Angle down' className='angleDown_Icon' />
              </Link>
              <ul className='Too_Downdd'>
                <ul className='Too_Downdd_main'>
                  <ul className='Too_Downdd_List'>
                    <li><h3>Our services</h3></li>
                    {services.map(service => (
                      <li
                        key={service.id}
                        onMouseEnter={() => setHoveredService(service.id)}
                        onMouseLeave={handleMouseLeave}  // Updated mouse leave logic
                      >
                        <Link to={service.link}>{service.title}</Link>
                      </li>
                    ))}
                  </ul>

                  {/* Conditional rendering of the hovered service details */}
                  <div
                    className='NAv_Icon_Dlts'
                    onMouseEnter={() => setIsHoveringDetails(true)}  // Prevent clearing when hovering on details
                    onMouseLeave={() => {
                      setIsHoveringDetails(false);  // Clear state when leaving the details section
                      handleMouseLeave();
                    }}
                  >
                    {hoveredService !== null ? (
                      <>
                        <img src={services[hoveredService - 1].img} alt={services[hoveredService - 1].title} />
                        <div className='NAv_Icon_Dlts_Main'>
                          <h3>{services[hoveredService - 1].title}</h3>
                          <p>{services[hoveredService - 1].description}</p>
                          <Link to={services[hoveredService - 1].link}>
                            Learn more <img src={Arrow1} className='Arrow Icon' alt='Arrow' />
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={ServicesImg1} alt='Services banner' />
                        <div className='NAv_Icon_Dlts_Main'>
                          <h3>Call Handling & Management Solutions</h3>
                          <p>Businesses often encounter challenges in managing calls effectively, including concerns regarding response times, customer satisfaction, and operational efficiency.</p>
                          <Link to='/caling-handling-management-solutions'>
                            Learn more <img src={Arrow1} className='Arrow Icon' alt='Arrow' />
                          </Link>
                        </div>
                      </>
                    )}
                  </div>

                </ul>
              </ul>
            </li>
            <li>
              <Link to="/about" aria-label="Go to About">About Us</Link>
            </li>
            <li>
              <Link to="/careers" aria-label="Go to Careers">Careers</Link>
            </li>
            <li>
              <Link to="/news" aria-label="Go to News">News</Link>
            </li>
            <li>
              <Link to="/contact-us" aria-label="Go to Contact Us" className='contact-btn'>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='search_dropDown'>
        <div className='search_dropDown_Box'>
          <div className='search_dropDown_Box_Form'>
            <input
              type='text'
              placeholder='Search here...'
              aria-label='Search input'
            />
            <button className='SearchMain_btn' onClick={handleCloseSearch}>
              <img src={SearchIcon} alt='Search Icon' />
              <span>Search</span>
            </button>
          </div>
          <span className='Close_Search_box' onClick={handleCloseSearch}></span>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
