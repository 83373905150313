// src/App.js
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'; // Import AuthProvider
import TopNavbar from './components/TopNavbar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LiveChat from './components/LiveChat';
import CalendlyPopup from './components/CalendlyPopup';
import './App.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Logo from './components/Img/logo-icon.png';
import AdminPage from './admin/AdminPage';

import ScrollToTop from './components/ScrollToTop';


import FAQDetail from './components/FAQDetail';

// Import Cookie Components
import CookieConsentBanner from './components/CookieConsentBanner';
import CookieSettingsModal from './components/CookieSettingsModal';

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Careers = lazy(() => import('./pages/Careers'));
const News = lazy(() => import('./pages/News'));
const Faq = lazy(() => import('./pages/Faq'));
const AllNews = lazy(() => import('./pages/AllNews'));
const ReadNews = lazy(() => import('./pages/ReadNews'));
const Contact = lazy(() => import('./pages/Contact'));
const Services1 = lazy(() => import('./pages/Services1'));
const Services10 = lazy(() => import('./pages/Services10'));
const Services2 = lazy(() => import('./pages/Services2'));
const Services3 = lazy(() => import('./pages/Services3'));
const Services30 = lazy(() => import('./pages/Services30'));
const Services31 = lazy(() => import('./pages/Services31'));
const Services32 = lazy(() => import('./pages/Services32'));
const Services301 = lazy(() => import('./pages/Services301'));
const Services302 = lazy(() => import('./pages/Services302'));
const Services303 = lazy(() => import('./pages/Services303'));
const Services304 = lazy(() => import('./pages/Services304'));
const Services4 = lazy(() => import('./pages/Services4'));
const Services41 = lazy(() => import('./pages/Services41'));
const Services42 = lazy(() => import('./pages/Services42'));
const Services43 = lazy(() => import('./pages/Services43'));
const Services401 = lazy(() => import('./pages/Services401'));
const Services402 = lazy(() => import('./pages/Services402'));
const Services403 = lazy(() => import('./pages/Services403'));
const Services404 = lazy(() => import('./pages/Services404'));
const Services405 = lazy(() => import('./pages/Services405'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const AdminLogin = lazy(() => import('./admin/ALogin'));

function App() {
  return (
    <AuthProvider> {/* Wrap your application with AuthProvider */}
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  const [isCookieModalOpen, setCookieModalOpen] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(() => localStorage.getItem('cookiesAccepted') === 'true');

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  const handleRejectCookies = () => {
    setCookiesAccepted(false);
    localStorage.setItem('cookiesAccepted', 'false');
  };

  const handleManageCookies = () => setCookieModalOpen(true);
  const handleCloseCookieModal = () => setCookieModalOpen(false);

  // Hide nav, footer, LiveChat, and CalendlyPopup on any route under /admin
  const shouldRenderNavAndFooter = !location.pathname.startsWith('/admin');

  return (
    <div>
      {shouldRenderNavAndFooter && <TopNavbar />}
      {shouldRenderNavAndFooter && <Navbar />}

      <ScrollToTop />

      <Suspense fallback={<div className="Page_Loader"><img src={Logo} alt="Loader logo" /></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/news" element={<News />} />
          <Route path="/all-news" element={<AllNews />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/read-a-news/:id/:title" element={<ReadNews />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/caling-handling-management-solutions" element={<Services1 />} />
          <Route path="/caling-handling-management-solutions1" element={<Services10 />} />
          <Route path="/recruitment-services" element={<Services2 />} />
          <Route path="/consultation-services" element={<Services3 />} />
          <Route path="/business-consultancy" element={<Services30 />} />
          <Route path="/cyber-solutions" element={<Services31 />} />
          <Route path="/risk-solutions" element={<Services32 />} />
          <Route path="/diaspora-services" element={<Services301 />} />
          <Route path="/environmental-social-and-governance" element={<Services302 />} />
          <Route path="/compliance-policies-&-procedures" element={<Services303 />} />
          <Route path="/immigration-&-relocation" element={<Services304 />} />
          <Route path="/business-support-services" element={<Services4 />} />
          <Route path="/diary-management" element={<Services41 />} />
          <Route path="/lifestyle-management" element={<Services42 />} />
          <Route path="/back-office-support" element={<Services43 />} />
          <Route path="/general-admin-services" element={<Services401 />} />
          <Route path="/transcription-services" element={<Services402 />} />
          <Route path="/basic-website" element={<Services403 />} />
          <Route path="/content-writing" element={<Services404 />} />
          <Route path="/social-media-support" element={<Services405 />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/admin/admin-login" element={<AdminLogin />} />


          <Route path='/faq/call-answering' element={<FAQDetail section="Call Answering" />} />
        <Route path='/faq/recruitment' element={<FAQDetail section="Recruitment" />} />
        <Route path='/faq/consulting' element={<FAQDetail section="Consulting" />} />
        <Route path='/faq/privacy-and-security' element={<FAQDetail section="Privacy and Security" />} />


          {/* AdminPage routes under "/admin" */}
          <Route path="/admin/*" element={<AdminPage />} />
        </Routes>
      </Suspense>

      {shouldRenderNavAndFooter && <Footer />}

      {/* Remove LiveChat and CalendlyPopup from admin pages */}
      {!location.pathname.startsWith('/admin') && (
        <>
          <LiveChat />
          <CalendlyPopup />
        </>
      )}

      {!cookiesAccepted && (
        <CookieConsentBanner
          onAccept={handleAcceptCookies}
          onReject={handleRejectCookies}
          onManage={handleManageCookies}
        />
      )}
      <CookieSettingsModal isOpen={isCookieModalOpen} onClose={handleCloseCookieModal} />
    </div>
  );
}

export default App;
