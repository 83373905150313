import React, { useState } from 'react';
import config from '../config'; // Assuming you have a config file for API_BASE_URL
import MessageEditor from './MessageEditor';

const APostNewsPage = () => {
  const [formData, setFormData] = useState({
    date: '',
    title: '',
    image: null,
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleEditorChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const postData = new FormData();
    postData.append('date', formData.date);
    postData.append('title', formData.title);
    postData.append('image', formData.image || '');
    postData.append('description', formData.description);

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/news/update/news/`, {
        method: 'POST',
        body: postData,
      });

      const result = await response.json();

      if (response.ok) {
        setSuccess('News posted successfully!');
        setFormData({
          date: '',
          title: '',
          image: null,
          description: ''
        });
      } else {
        setError(`Failed to post news: ${result.message || 'Server error'}`);
      }
    } catch (err) {
      setError('Error posting news.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>Post a News / Blog</h3>
      </div>

      <form className='AaPost_Sec' onSubmit={handleSubmit}>
        <div className='Post_input'>
          <input
            type='date'
            name='date'
            value={formData.date}
            placeholder='Date'
            onChange={handleChange}
            required
          />
        </div>

        <div className='Post_input'>
          <input
            type='text'
            name='title'
            value={formData.title}
            placeholder='Title'
            onChange={handleChange}
            required
          />
        </div>

        <div className='Post_input file_naBba'>
          <label>Upload News Banner</label>
          <input
            type='file'
            name='image'
            onChange={handleFileChange}
            required
          />
        </div>

        <div className='Post_input'>
          <p>News Description</p>
          <MessageEditor
            value={formData.description}
            onChange={handleEditorChange}
          />
        </div>

        <div className='Post_input'>
          <button type='submit' disabled={loading}>
            {loading ? 'Posting...' : 'Post News'}
          </button>
        </div>

        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}
      </form>
    </div>
  );
};

export default APostNewsPage;
