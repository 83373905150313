// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import config from '../config'; // Assuming you have a config file for API_BASE_URL

// const AJobsPage = () => {

//     const [jobs, setJobs] = useState([]); // State to store jobs
//     const [activeIndex, setActiveIndex] = useState(null); // Track which job is open
//     const [nextPage, setNextPage] = useState(null); // State for next page URL
//     const [previousPage, setPreviousPage] = useState(null); // State for previous page URL
//     const [loading, setLoading] = useState(true); // State for loading

//     // Fetch job data from the API
//     const fetchJobs = async (url) => {
//         setLoading(true);
//         try {
//             const response = await fetch(url);
//             const data = await response.json();
//             setJobs(data.results); // Set jobs data
//             setNextPage(data.next); // Set next page URL for pagination
//             setPreviousPage(data.previous); // Set previous page URL for pagination
//         } catch (error) {
//             console.error("Error fetching jobs:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchJobs(`${config.API_BASE_URL}/api/jobs/update/jobs/`); // Fetch initial jobs
//     }, []);

//     // Handle toggling of active accordion items
//     const toggleAccordion = (index) => {
//         if (activeIndex === index) {
//             setActiveIndex(null); // Close accordion if it's already active
//         } else {
//             setActiveIndex(index); // Open clicked accordion
//         }
//     };

//     // Function to handle deletion of a job
//     const handleDeleteJob = async (id) => {
//         const confirmed = window.confirm('Are you sure you want to delete this job?');
//         if (confirmed) {
//             try {
//                 const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/${id}/`, {
//                     method: 'DELETE',
//                 });
//                 if (response.ok) {
//                     // Filter out the deleted job from the state
//                     setJobs(jobs.filter(job => job.id !== id));
//                     alert('Job removed successfully!');
//                 } else {
//                     alert('Failed to remove job.');
//                 }
//             } catch (error) {
//                 console.error('Error deleting job:', error);
//                 alert('An error occurred while trying to delete the job.');
//             }
//         }
//     };

//     const handleNextPage = () => {
//         if (nextPage) {
//             fetchJobs(nextPage); // Fetch jobs from next page
//         }
//     };

//     const handlePreviousPage = () => {
//         if (previousPage) {
//             fetchJobs(previousPage); // Fetch jobs from previous page
//         }
//     };

//     return (
//         <div className='aNNagPage'>
//             <div className='Aa_Header'>
//                 <h3>Posted Jobs</h3>
//                 <span>{jobs.length}</span> {/* Display the number of jobs */}
//             </div>

//             <div className="job-opportunities-section">
//                 <div className="job-main">
//                     {loading ? (
//                         <p>Loading jobs...</p> // Loading state
//                     ) : (
//                         <ul>
//                             {jobs.map((item, index) => (
//                                 <li key={item.id} className={`job-item ${activeIndex === index ? 'active' : ''}`}>
//                                     <div className="job-content">
//                                         <div className='All_Aaa_Btns Allja_width'>
//                                         <Link 
//                                           to={`/admin/edit-job/${item.id}/${encodeURIComponent(item.title)}/${encodeURIComponent(item.description)}/${encodeURIComponent(item.requirements)}`} 
//                                           className='Gg_btn main_Gg_btn'
//                                         >
//                                           Edit Job
//                                         </Link>




//                                             <button 
//                                                 className='Gg_btn' 
//                                                 onClick={() => handleDeleteJob(item.id)} // Call delete handler
//                                             >
//                                                 Remove
//                                             </button>
//                                         </div>
//                                         <div className="job-title">
//                                             <h3>{item.title}</h3>
//                                             <span className="job-date">{`Posted on ${item.start_date}`}</span> {/* Date displayed here */}
//                                         </div>
//                                         <div className={`job-description ${activeIndex === index ? 'show' : ''}`}>
//                                             <p>{item.description}</p>
//                                         </div>
//                                         <button
//                                             className="toggle-button"
//                                             onClick={() => toggleAccordion(index)}
//                                         >
//                                             {activeIndex === index ? 'Close Description' : 'View Job Description'}
//                                         </button>
//                                     </div>
//                                 </li>
//                             ))}
//                         </ul>
//                     )}
//                 </div>

//                 {/* Pagination buttons for navigating between pages */}
//                 <div className="pagination-buttons">
//                     <button
//                         onClick={handlePreviousPage}
//                         disabled={!previousPage}
//                         className='btn btn-danger btn-lg mx-2'
//                     >
//                         Previous
//                     </button>
//                     <button
//                         onClick={handleNextPage}
//                         disabled={!nextPage}
//                         className='btn btn-primary btn-lg mx-2'
//                     >
//                         Next
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AJobsPage;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html'; // Optional for security
import config from '../config';

const AJobsPage = () => {

    const [jobs, setJobs] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchJobs = async (url) => {
        setLoading(true);
        try {
            const response = await fetch(url);
            const data = await response.json();
            setJobs(data.results);
            setNextPage(data.next);
            setPreviousPage(data.previous);
        } catch (error) {
            console.error("Error fetching jobs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs(`${config.API_BASE_URL}/api/jobs/update/jobs/`);
    }, []);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleDeleteJob = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this job?');
        if (confirmed) {
            try {
                const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/${id}/`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    setJobs(jobs.filter(job => job.id !== id));
                    alert('Job removed successfully!');
                } else {
                    alert('Failed to remove job.');
                }
            } catch (error) {
                console.error('Error deleting job:', error);
                alert('An error occurred while trying to delete the job.');
            }
        }
    };

    const handleNextPage = () => {
        if (nextPage) {
            fetchJobs(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchJobs(previousPage);
        }
    };

    return (
        <div className='aNNagPage'>
            <div className='Aa_Header'>
                <h3>Posted Jobs</h3>
                <span>{jobs.length}</span>
            </div>

            <div className="job-opportunities-section">
                <div className="job-main">
                    {loading ? (
                        <p>Loading jobs...</p>
                    ) : (
                        <ul>
                            {jobs.map((item, index) => (
                                <li key={item.id} className={`job-item ${activeIndex === index ? 'active' : ''}`}>
                                    <div className="job-content">
                                        <div className='All_Aaa_Btns Allja_width'>
                                            <Link 
                                                to={`/admin/edit-job/${item.id}/${encodeURIComponent(item.title)}`} 
                                                className='Gg_btn main_Gg_btn'
                                            >
                                                Edit Job
                                            </Link>
                                            <button 
                                                className='Gg_btn' 
                                                onClick={() => handleDeleteJob(item.id)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                        <div className="job-title">
                                            <h3>{item.title}</h3>
                                            <span className="job-date">{`Posted on ${item.start_date}`}</span>
                                        </div>
                                        <div className={`job-description ${activeIndex === index ? 'show' : ''}`}>
                                            {/* Render description as HTML */}
                                            <p dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(item.description) // Sanitizing HTML
                                            }}></p>
                                        </div>
                                        <button
                                            className="toggle-button"
                                            onClick={() => toggleAccordion(index)}
                                        >
                                            {activeIndex === index ? 'Close Description' : 'View Job Description'}
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="pagination-buttons">
                    <button
                        onClick={handlePreviousPage}
                        disabled={!previousPage}
                        className='btn btn-danger btn-lg mx-2'
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={!nextPage}
                        className='btn btn-primary btn-lg mx-2'
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AJobsPage;
