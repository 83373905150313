import React, { useEffect, useState } from 'react';
import config from '../config'; // Assuming you have a config file for API_BASE_URL

const AVisitorsPage = () => {
  const [tableData, setTableData] = useState([]); // State to hold visitor data
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [nextPage, setNextPage] = useState(null); // URL for the next page
  const [previousPage, setPreviousPage] = useState(null); // URL for the previous page
  const [currentPage, setCurrentPage] = useState(1); // Current page number

  const fetchVisitorData = async (page = 1) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/client-tracking/ips/?page=${page}`);
      const data = await response.json();
      
      // Map the results to the format needed for the table
      const formattedData = data.results.map(visitor => ({
        date: visitor.created_at, // Use the created_at field for the date
        ipAddress: visitor.ip_address // Use the ip_address field
      }));
      setTableData(formattedData); // Update the tableData state
      setNextPage(data.next); // Set the next page URL
      setPreviousPage(data.previous); // Set the previous page URL
      setCurrentPage(page); // Update the current page number
    } catch (error) {
      console.error('Error fetching visitor data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchVisitorData(); // Fetch the initial visitor data on component mount
  }, []); // Empty dependency array to run once on component mount

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleNextPage = () => {
    if (nextPage) {
      fetchVisitorData(currentPage + 1); // Fetch data for the next page
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      fetchVisitorData(currentPage - 1); // Fetch data for the previous page
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display loading message while fetching data
  }

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>Site visitors</h3>
      </div>
      <table className='Hha_Tbale' border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Date</th>
            <th>IP Address</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{index + 1 + (currentPage - 1) * 10}</td> {/* S/N: Serial Number starting from 1 */}
              <td>{formatDate(row.date)}</td> {/* Date formatted */}
              <td>{row.ipAddress}</td> {/* IP Address */}
            </tr>
          ))}
        </tbody>
      </table> <br/>

      <div className='pagination-buttons'>
        <button
          onClick={handlePreviousPage}
          disabled={!previousPage}
          className='btn btn-danger btn-lg mx-2'
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={!nextPage}
          className='btn btn-primary btn-lg mx-2'
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AVisitorsPage;
