import React from 'react';
import { Link } from 'react-router-dom';
import HomeBanner from './Img/aHome-banner.png';

const AHomePage = () => {
  return (
    <div className='aPage'>
        <div className='aHome_page'>
            <h3>Welcome back Admin!</h3>
            <p>Simul Management Solutions</p>
            <Link to="/">Visit website</Link>
            <img src={HomeBanner} alt='Home banner'></img>
        </div>
    </div>
  );
};

export default AHomePage;
