import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import config from '../config'; // Assuming you have a config file for API_BASE_URL

const AReadNewsPage = () => {
  // Get the id and title from the URL using useParams
  const { id } = useParams(); // Only using id for deletion
  const navigate = useNavigate(); // Hook for navigation

  // State to store news details and loading/error states
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch news details from the API
  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/news/update/news/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch news details');
        }
        const data = await response.json();
        setNews(data); // Store the fetched news data
      } catch (err) {
        setError(err.message); // Handle errors
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchNewsDetails();
  }, [id]); // Fetch when id changes

  // Function to handle news deletion
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this news item?")) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/news/update/news/${id}`, {
          method: 'DELETE', // Specify DELETE method
        });

        if (!response.ok) {
          throw new Error('Failed to delete news');
        }

        // Optionally, navigate back to the news list or show a success message
        navigate('/news'); // Replace with your news listing route
      } catch (err) {
        setError(err.message); // Handle any errors
      }
    }
  };

  if (loading) {
    return <div>Loading news...</div>;
  }

  if (error) {
    return <div>Error fetching news: {error}</div>;
  }

  if (!news) {
    return <div>No news found</div>; // Handle case where no news is found
  }

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>News / Blogs</h3>
        <div className='All_Aaa_Btns'>
          <Link to={`/admin/edit-news/${news.id}/${encodeURIComponent(news.title)}`}  className='Gg_btn main_Gg_btn'>Edit News</Link>
          {/* Update the Remove button to call handleDelete */}
          <button className='Gg_btn' onClick={handleDelete}>Remove</button>
        </div>
      </div>

      <div className='Read_News_Sec'>
        <div className='Read_Box'>
          <div className='Read_BOx_header'>
            <h3 className='big-text'>{news.title}</h3>
            <span>{new Date(news.date).toLocaleDateString()} | SMUL News / Blogs</span>
            {news.image && <img src={news.image} alt='News banner' />}
          </div>
          <div className='Read_BOx_Body'>
            <div dangerouslySetInnerHTML={{ __html: news.description }} />
            {/* Additional content can be added here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AReadNewsPage;
