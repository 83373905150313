import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SiteLogo from './Img/site-logo.png';
import SearchIcon from './Img/search-icon.svg';
import AngleIcon from './Img/angle-icon.svg';
import FaceBookIcon from './Img/facebook-icon.svg';
import InstagramIcon from './Img/instagram-icon.svg';
import LinkedinIcon from './Img/linkedin-icon.svg';
import ChatIcon from './Img/chat-icon.svg';

import LlTlkBanner from './Img/LlTlkBanner.jpg';

import WhatSappIcon from './Img/whatsapp-icon.svg';

import CallIcon from './Img/callIcon.svg';


import SupportVideo from './Img/support-video.mp4';



const Navbar = () => {

  const [isNavActive, setIsNavActive] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isLetTalkVisible, setIsLetTalkVisible] = useState(false); // State for Let_talk_sec visibility

  const videoRef = useRef(null); 
  const sectionRef = useRef(null); 

  const handleNavToggle = () => {
    setIsNavActive(!isNavActive);
  };

  const handleNavBodyClick = () => {
    setIsNavActive(false);
  };

  const handleLinkClick = () => {
    setIsNavActive(false);
  };

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleCloseSearch = () => {
    setIsSearchVisible(false);
  };

  const handleClickOutside = (event) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target)) {
      if (isLetTalkVisible) {
        setIsLetTalkVisible(false); // Hide Let_talk_sec
        if (videoRef.current) {
          videoRef.current.pause(); // Stop playing the video
          videoRef.current.currentTime = 0; // Reset to start
        }
      }
    }
  };

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.loop = true;
        videoRef.current.play();
        setIsLetTalkVisible(true); // Show Let_talk_sec
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.loop = false;
        setIsLetTalkVisible(false); // Hide Let_talk_sec
      }
    }
  };

  const handleCloseButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setIsLetTalkVisible(false); // Hide Let_talk_sec
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLetTalkVisible]);


  return (

    <nav className={`Main_navBar ${isNavActive ? 'Active_Mobile_Nav' : ''} ${isSearchVisible ? 'Show_Search_DropDown' : ''}`}>
      <div className='site-container'>
        <div className='First_Mm_Nav'>
          <div className='Left_L_Main_Nav'>
            <Link to="/" className='site-logo'>
              <img src={SiteLogo} alt='Site Logo' />
            </Link>
          </div>
          <div className='Right_R_Main_Nav'>
            <div className='Pp_All_Daf'>
            {/* <button className='search_btn' onClick={handleSearchToggle}>
                <img src={SearchIcon} alt='Search Icon' />
                <span>Search</span>
              </button> */}
              <div className='Nav_Social_icons'>
                <p>Connect with us</p>
                <span><img src={AngleIcon} alt='Angle Icon' /></span>
                <div className='social_Icons_main'>
                  <a href='https://www.facebook.com' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
                    <img src={FaceBookIcon} alt='Facebook Icon' />
                  </a>
                  <a href='https://www.instagram.com' aria-label='Instagram' target='_blank' rel='noopener noreferrer'>
                    <img src={InstagramIcon} alt='Instagram Icon' />
                  </a>
                  <a href='https://www.linkedin.com' aria-label='LinkedIn' target='_blank' rel='noopener noreferrer'>
                    <img src={LinkedinIcon} alt='LinkedIn Icon' />
                  </a>
                </div>
              </div> 
               {/* <button className='Chat_Btn' onClick={toggleVideoPlayback}>
                <img src={ChatIcon} alt='Chat Icon' />
                <span>Let’s talk</span>
              </button> */}

              <div className='Nav_Toggler' onClick={handleNavToggle}></div>
            </div>
          </div>
        </div>
     
      </div>
      <div className='Nav_Body' onClick={handleNavBodyClick}></div>
      <div className='Mobile_nav'>
      <div className='Nav_Ll_Dtxt'>
            <p>
              <span>Call:</span> 
              <a href='tel:+2349066484496' aria-label='Call us at +2349066484496'>+2349066484496</a>
            </p>
            &nbsp;&nbsp;&nbsp;
            <p>
              <span>Email:</span>
              <a href='mailto:info@simu-l.com' aria-label='Email us at info@simu-l.com'>info@simu-l.com</a>
            </p>
          </div>

      <div className='Nav_Social_icons'>
                <p>Connect with us</p>
                <span><img src={AngleIcon} alt='Angle Icon' /></span>
                <div className='social_Icons_main'>
                  <a href='https://web.facebook.com/SimulMgt?_rdc=1&_rdr' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
                    <img src={FaceBookIcon} alt='Facebook Icon' />
                  </a>
                  <a href='https://www.instagram.com/simulmgt/?igsh=MWJqczB1a3FudHFlbw%3D%3D' aria-label='Instagram' target='_blank' rel='noopener noreferrer'>
                    <img src={InstagramIcon} alt='Instagram Icon' />
                  </a>
                  <a href='https://www.linkedin.com' aria-label='LinkedIn' target='_blank' rel='noopener noreferrer'>
                    <img src={LinkedinIcon} alt='LinkedIn Icon' />
                  </a>
                </div>
              </div>
              <ul className='Mmn_UlL1'>
              <li><Link to="/contact-us" aria-label="Go to Contact Us" className='contact-btn' onClick={handleLinkClick}>Contact Us</Link></li>
              <li><Link to="/" aria-label="Go to Home" onClick={handleLinkClick}>Home</Link></li>
              <li><Link to="/about" aria-label="Go to About" onClick={handleLinkClick}>About Us</Link></li>
              <li><Link to="/careers" aria-label="Go to Careers" onClick={handleLinkClick}>Careers</Link></li>
              <li><Link to="/news" aria-label="Go to News" onClick={handleLinkClick}>News</Link></li>
              <li><p>Our Services</p></li>
              <li>
              <Link to="/caling-handling-management-solutions" onClick={handleLinkClick}>Call Handling & Management Solutions</Link>
            </li>
            <li>
              <Link to="/recruitment-services" onClick={handleLinkClick}>Recruitment Services</Link>
            </li>
            <li>
              <Link to="/consultation-services" onClick={handleLinkClick}>Consultation Services</Link>
            </li>
            <li>
              <Link to="/business-support-services" onClick={handleLinkClick}>Business Support Services</Link>
            </li>

        </ul>
      </div>


      <div className='search_dropDown'>
      <div className='search_dropDown_Box'>
      <div className='search_dropDown_Box_Form'>
          <input
            type='text'
            placeholder='Search here...'
            aria-label='Search input'
          />
          <button className='SearchMain_btn' onClick={handleCloseSearch}>
          <img src={SearchIcon} alt='Search Icon' />
            <span>Search</span>
            </button>
          </div>
          <span className='Close_Search_box' onClick={handleCloseSearch}></span>
        </div>
      </div>


      <div className={`Let_talk_sec ${isLetTalkVisible ? 'Show_Let_talk_sec' : ''}`} ref={sectionRef}>
        <div className='Let_talk_Box'>
        <div className='Let_talk_Banner'>
        <button className='Close_Let_talk_sec' onClick={handleCloseButtonClick}></button>
          <img src={LlTlkBanner} alt='Banner' />

          <video ref={videoRef} loop>
            <source src={SupportVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
          <div className='Let_talk_Dlt'>
          <h3>Let's Talk</h3>
          
          <p className='p'>You can message us via email or whatsapp. You can use our live chat for quick response.</p>

          <div className='Ll_btns'>
          <a
            href="https://wa.me/2349066484496"
            className="whatsapp-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={WhatSappIcon} alt="Whatsapp icon" />
            <span>Whatsapp</span>
          </a>

          <a href="tel:+2349066484496">
            <img src={CallIcon} alt="Call icon" />
            <span>Call</span>
          </a>
          </div>
        </div>
        </div>
      </div>



    </nav>

  );
};

export default Navbar;
