// CalendlyPopup.js
import React from 'react';
import { PopupWidget } from 'react-calendly';

const CalendlyPopup = () => {
  return (
    <div>
      {/* <h1>Book a free Consultation</h1> */}
      <PopupWidget
        url="https://calendly.com/simu-l-info/30min"
        rootElement={document.getElementById('root')}
        text="Book a free Consultation"
        textColor="#ffffff"
        color="#A0B11B"
      />
    </div>
  );
};

export default CalendlyPopup;
