// import React, { useState, useEffect } from 'react';
// import config from '../config'; // Assuming you have a config file for API_BASE_URL

// const APostJobPage = () => {

//   const [formData, setFormData] = useState({

//     end_date: '',
//     title: '',
//     description: '',
//     requirements: '' // Add requirements field
//   });

//   // Function to format today's date as 'YYYY-MM-DD' for the date input
//   const getFormattedDate = () => {
//     const today = new Date();
//     const yyyy = today.getFullYear();
//     const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//     const dd = String(today.getDate()).padStart(2, '0');
//     return `${yyyy}-${mm}-${dd}`; // Format as YYYY-MM-DD
//   };

//   // Set the default date when the component mounts
//   useEffect(() => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       end_date: getFormattedDate() // Set today's date as the default end date
//     }));
//   }, []);

//   // Update form data when the user types in the input fields
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   // Handle the job post submission
//   const handlePostJob = async () => {
//     const { end_date, title, description, requirements } = formData;

//     if (!end_date || !title || !description || !requirements) {
//       alert('Please fill in all fields');
//       return;
//     }

//     try {
//       const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           end_date,
//           title,
//           description,
//           requirements // Include requirements in the request body
//         })
//       });

//       const result = await response.json();

//       if (response.ok) {
//         alert('Job posted successfully!');
//         // Optionally reset the form after submission
//         setFormData({
//           end_date: getFormattedDate(), // Reset to today's date
//           title: '',
//           description: '',
//           requirements: '' // Reset requirements field
//         });
//       } else {
//         alert('Failed to post job: ' + result.message);
//       }
//     } catch (error) {
//       console.error('Error posting job:', error);
//       alert('An error occurred while posting the job.');
//     }
//   };

//   return (
//     <div className='aNNagPage'>
//       <div className='Aa_Header'>
//         <h3>Post a Job</h3>
//       </div>

//       <div className='AaPost_Sec'>
//         <div className='Post_input'>
//           <input
//             type='date' // Use type 'date' for date selection
//             name='end_date'
//             value={formData.end_date}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className='Post_input'>
//           <input
//             type='text'
//             placeholder='Job Title'
//             name='title'
//             value={formData.title}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className='Post_input'>
//           <textarea
//             placeholder='Job Description'
//             name='description'
//             value={formData.description}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className='Post_input'>
//           <textarea
//             placeholder='Job Requirements' // Placeholder for the requirements field
//             name='requirements' // Name attribute for the requirements field
//             value={formData.requirements} // Bind value to requirements state
//             onChange={handleInputChange} // Handle input change
//           />
//         </div>

//         <div className='Post_input'>
//           <button onClick={handlePostJob}>Post Job</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default APostJobPage;

import React, { useState, useEffect } from 'react';
import config from '../config'; // Assuming you have a config file for API_BASE_URL
import MessageEditor from './MessageEditor';

const APostJobPage = () => {
  const [formData, setFormData] = useState({
    end_date: '',
    title: '',
    description: '',
    requirements: ''
  });

  const getFormattedDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      end_date: getFormattedDate()
    }));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditorChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handlePostJob = async () => {
    const { end_date, title, description, requirements } = formData;

    if (!end_date || !title || !description || !requirements) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/jobs/update/jobs/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ end_date, title, description, requirements })
      });

      const result = await response.json();

      if (response.ok) {
        alert('Job posted successfully!');
        setFormData({
          end_date: getFormattedDate(),
          title: '',
          description: '',
          requirements: ''
        });
      } else {
        alert('Failed to post job: ' + result.message);
      }
    } catch (error) {
      console.error('Error posting job:', error);
      alert('An error occurred while posting the job.');
    }
  };

  return (
    <div className='aNNagPage'>
      <div className='Aa_Header'>
        <h3>Post a Job</h3>
      </div>

      <div className='AaPost_Sec'>
        <div className='Post_input'>
          <input
            type='date'
            name='end_date'
            value={formData.end_date}
            onChange={handleInputChange}
          />
        </div>

        <div className='Post_input'>
          <input
            type='text'
            placeholder='Job Title'
            name='title'
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>

        <div className='Post_input'>
          <p>Job Description</p>
          <MessageEditor
            value={formData.description}
            onChange={(value) => handleEditorChange('description', value)}
          />
        </div>

        <div className='Post_input'>
          <p>Job Requirements</p>
          <MessageEditor
            value={formData.requirements}
            onChange={(value) => handleEditorChange('requirements', value)}
          />
        </div>

        <div className='Post_input'>
          <button onClick={handlePostJob}>Post Job</button>
        </div>
      </div>
    </div>
  );
};

export default APostJobPage;
